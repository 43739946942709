import React from 'react'
import { cn } from 'lib/utils'

export type TypographyProps = {
  children: React.ReactNode
  className?: string
}

export const Heading1 = ({ children, className }: TypographyProps) => (
  <h1 className={cn('text-4xl md:text-5xl font-condensed font-bold text-primary ', className)}>
    {children}
  </h1>
)

export const Heading2 = ({ children, className }: TypographyProps) => (
  <h2 className={cn('text-3xl font-condensed font-bold text-primary ', className)}>{children}</h2>
)

export const Heading3 = ({ children, className }: TypographyProps) => (
  <h3 className={cn('text-2xl font-condensed font-bold text-primary ', className)}>{children}</h3>
)

export const Heading4 = ({ children, className }: TypographyProps) => (
  <h4 className={cn('text-2xl font-condensed font-bold', className)}>{children}</h4>
)

export const Heading5 = ({ children, className }: TypographyProps) => (
  <h5 className={cn('text-xl font-semibold ', className)}>{children}</h5>
)

export const Heading6 = ({ children, className }: TypographyProps) => (
  <h6 className={cn('text-lg font-semibold ', className)}>{children}</h6>
)

export const Paragraph = ({ children, className }: TypographyProps) => (
  <p className={cn('text-base font-normal', className)}>{children}</p>
)
