import { HomepageSections } from 'constants/common/routes'
import { Logo } from 'components/ui/Logo'
import { Paragraph } from 'components/ui/Typography'
import { ClientEnv } from 'utils/clientEnv'

type FooterProps = {
  variant?: 'default' | 'minimal'
}

export const Footer = ({ variant = 'default' }: FooterProps) => (
  <footer
    className="bottom-0 w-full bg-primary text-surface"
    {...(variant !== 'minimal' && { id: HomepageSections.contact })}
  >
    {variant !== 'minimal' && (
      <div className="px-6 py-8 sm:px-6 sm:py-[1.375rem] grid gap-4 sm:grid-cols-2 lg:grid-cols-3 items-center justify-center border-b border-white/20">
        <div className="flex sm:justify-self-start text-nowrap">
          <Paragraph className="text-2xl font-condensed font-bold text-surface mr-2">
            Need help?
          </Paragraph>
          <Paragraph className="text-2xl font-condensed font-bold mr-2">Call</Paragraph>
          <Paragraph className="text-2xl font-condensed font-bold text-process">
            <a href="tel:877-635-2647">877-635-2647</a>
          </Paragraph>
        </div>
        <div className="hidden lg:block sm:justify-self-center">
          <Logo titleId="footer-logo" variant="white" className="w-[159px] h-[44px]" />
        </div>
        <div className="sm:justify-self-end text-center underline">
          <a href="https://debtwave.org/" target="_blank" rel="noreferrer">
            More about Debtwave.org
          </a>
        </div>
      </div>
    )}
    <div className="grid grid-cols-1 justify-center gap-3 py-5 px-6">
      <div className="flex justify-center">
        <Paragraph className="text-xs text-center max-w-[1000px]">
          FileMyBOIR and FileMyBOIR.org are dbas of DebtWave Credit Counseling, Inc. Debtwave is
          short for the full legal name Debtwave Credit Counseling, Inc., a federally approved
          501(c)3 non-profit corporation licensed and approved to provide financial literacy, debt
          management, and consumer credit counseling services in 46 states, plus Washington, DC.
        </Paragraph>
      </div>
      <div className="py-2 flex flex-wrap justify-center gap-y-2 gap-x-6 text-xs text-[0.8125rem] underline">
        <a href={ClientEnv.NEXT_PUBLIC_TERMS_OF_SERVICE_URL} target="_blank" rel="noreferrer">
          Terms of Service
        </a>
        <a href={ClientEnv.NEXT_PUBLIC_PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
          Privacy policy
        </a>
        <a href={ClientEnv.NEXT_PUBLIC_COOKIE_POLICY_URL} target="_blank" rel="noreferrer">
          Cookie policy
        </a>
        <a href={ClientEnv.NEXT_PUBLIC_DISCLAIMER_URL} target="_blank" rel="noreferrer">
          Disclaimer
        </a>
        <a href={ClientEnv.NEXT_PUBLIC_ACCEPTABLE_USE_POLICY_URL} target="_blank" rel="noreferrer">
          Acceptable Use Policy
        </a>
        <a href={ClientEnv.NEXT_PUBLIC_PERSONAL_DATA_USE_FORM_URL} target="_blank" rel="noreferrer">
          Do Not Sell or Share My Personal Information
        </a>
        <a href={ClientEnv.NEXT_PUBLIC_PERSONAL_DATA_USE_FORM_URL} target="_blank" rel="noreferrer">
          Limit the Use of my Sensitive Personal Information
        </a>
        <a href={ClientEnv.NEXT_PUBLIC_PERSONAL_DATA_USE_FORM_URL} target="_blank" rel="noreferrer">
          Data Subject Access Request
        </a>
      </div>
      <Paragraph className="text-xs text-[0.8125rem] text-center">© 2024 Debtwave</Paragraph>
    </div>
  </footer>
)
