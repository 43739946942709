import { SVGProps } from 'react'

type LogoProps = SVGProps<SVGSVGElement> & {
  variant?: 'white' | 'colored'
  titleId: string
}

export const Logo = ({ titleId, variant = 'colored', ...props }: LogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="154"
    height="44"
    viewBox="0 0 154 44"
    fill="none"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    <title id={titleId}>File My BOIR logo</title>
    <path
      d="M35.8714 34.468C35.8714 33.8397 35.7161 33.3564 35.4054 33.0181C35.0947 32.6798 34.6805 32.5106 34.1627 32.5106C33.8727 32.5106 33.6448 32.521 33.4791 32.5417C33.3203 32.5624 33.1926 32.5866 33.096 32.6142V36.0111C33.2133 36.1077 33.3825 36.2009 33.6034 36.2907C33.8243 36.3805 34.066 36.4253 34.3284 36.4253C34.6045 36.4253 34.8393 36.377 35.0326 36.2803C35.2328 36.1768 35.3951 36.0387 35.5193 35.8661C35.6436 35.6866 35.7334 35.4795 35.7886 35.2447C35.8438 35.0031 35.8714 34.7442 35.8714 34.468ZM36.876 34.468C36.876 34.8753 36.8208 35.2516 36.7103 35.5968C36.6067 35.942 36.4514 36.2389 36.2443 36.4875C36.0371 36.736 35.7817 36.9293 35.4779 37.0674C35.181 37.2055 34.8393 37.2745 34.4526 37.2745C34.1419 37.2745 33.8658 37.2331 33.6241 37.1503C33.3894 37.0674 33.2133 36.988 33.096 36.9121V39.0662H32.1328V31.9307C32.3607 31.8755 32.6437 31.8168 32.982 31.7546C33.3272 31.6856 33.7242 31.6511 34.173 31.6511C34.5873 31.6511 34.9601 31.7167 35.2915 31.8478C35.6229 31.979 35.906 32.1654 36.1407 32.4071C36.3755 32.6487 36.555 32.9456 36.6792 33.2977C36.8104 33.6429 36.876 34.033 36.876 34.468Z"
      fill={variant === 'white' ? 'white' : '#0C3642'}
    />
    <path
      d="M42.9849 34.4576C42.9849 34.8857 42.9227 35.2723 42.7984 35.6175C42.6742 35.9627 42.4981 36.2596 42.2703 36.5082C42.0493 36.7567 41.7835 36.9501 41.4728 37.0881C41.1621 37.2193 40.8238 37.2849 40.4579 37.2849C40.092 37.2849 39.7537 37.2193 39.443 37.0881C39.1323 36.9501 38.863 36.7567 38.6352 36.5082C38.4143 36.2596 38.2417 35.9627 38.1174 35.6175C37.9931 35.2723 37.931 34.8857 37.931 34.4576C37.931 34.0365 37.9931 33.6533 38.1174 33.3081C38.2417 32.956 38.4143 32.6556 38.6352 32.4071C38.863 32.1585 39.1323 31.9687 39.443 31.8375C39.7537 31.6994 40.092 31.6304 40.4579 31.6304C40.8238 31.6304 41.1621 31.6994 41.4728 31.8375C41.7835 31.9687 42.0493 32.1585 42.2703 32.4071C42.4981 32.6556 42.6742 32.956 42.7984 33.3081C42.9227 33.6533 42.9849 34.0365 42.9849 34.4576ZM41.9803 34.4576C41.9803 33.8501 41.8422 33.3702 41.566 33.0181C41.2968 32.6591 40.9274 32.4796 40.4579 32.4796C39.9884 32.4796 39.6156 32.6591 39.3394 33.0181C39.0702 33.3702 38.9355 33.8501 38.9355 34.4576C38.9355 35.0652 39.0702 35.5485 39.3394 35.9075C39.6156 36.2596 39.9884 36.4357 40.4579 36.4357C40.9274 36.4357 41.2968 36.2596 41.566 35.9075C41.8422 35.5485 41.9803 35.0652 41.9803 34.4576Z"
      fill={variant === 'white' ? 'white' : '#0C3642'}
    />
    <path
      d="M48.6099 37.1503C48.4166 36.6532 48.2094 36.087 47.9885 35.4518C47.7745 34.8097 47.5777 34.1435 47.3982 33.4531C47.2187 34.1435 47.0219 34.8097 46.8079 35.4518C46.5938 36.087 46.3902 36.6532 46.1969 37.1503H45.358C45.0404 36.4391 44.7297 35.6279 44.4259 34.7165C44.1221 33.8052 43.8356 32.8213 43.5664 31.765H44.602C44.671 32.0895 44.7539 32.4416 44.8505 32.8213C44.9472 33.2011 45.0473 33.5808 45.1509 33.9605C45.2613 34.3403 45.3753 34.7062 45.4926 35.0583C45.61 35.4104 45.7205 35.7176 45.824 35.98C45.9414 35.6555 46.0553 35.3138 46.1658 34.9547C46.2832 34.5888 46.3936 34.2229 46.4972 33.857C46.6008 33.4841 46.6974 33.1217 46.7872 32.7696C46.8838 32.4105 46.9667 32.0757 47.0357 31.765H47.8332C47.8953 32.0757 47.9712 32.4105 48.061 32.7696C48.1507 33.1217 48.2474 33.4841 48.351 33.857C48.4545 34.2229 48.5615 34.5888 48.672 34.9547C48.7894 35.3138 48.9068 35.6555 49.0241 35.98C49.1277 35.7176 49.2347 35.4104 49.3452 35.0583C49.4626 34.7062 49.5765 34.3403 49.6869 33.9605C49.7974 33.5808 49.901 33.2011 49.9976 32.8213C50.0943 32.4416 50.1771 32.0895 50.2462 31.765H51.2404C50.9711 32.8213 50.6846 33.8052 50.3808 34.7165C50.077 35.6279 49.7663 36.4391 49.4487 37.1503H48.6099Z"
      fill={variant === 'white' ? 'white' : '#0C3642'}
    />
    <path
      d="M51.9585 34.468C51.9585 33.9916 52.0276 33.5773 52.1657 33.2252C52.3037 32.8662 52.4867 32.5693 52.7145 32.3346C52.9424 32.0998 53.2047 31.9238 53.5016 31.8064C53.7985 31.689 54.1023 31.6304 54.413 31.6304C55.1379 31.6304 55.6937 31.8582 56.0803 32.3139C56.467 32.7626 56.6603 33.4496 56.6603 34.3748C56.6603 34.4162 56.6603 34.4714 56.6603 34.5405C56.6603 34.6026 56.6568 34.6613 56.6499 34.7165H52.9631C53.0045 35.2758 53.1668 35.7004 53.4498 35.9904C53.7329 36.2803 54.1748 36.4253 54.7754 36.4253C55.1138 36.4253 55.3968 36.3977 55.6247 36.3425C55.8594 36.2803 56.0355 36.2217 56.1528 36.1664L56.2875 36.9742C56.1701 37.0364 55.963 37.1019 55.6661 37.171C55.3761 37.24 55.0447 37.2745 54.6719 37.2745C54.2024 37.2745 53.7951 37.2055 53.4498 37.0674C53.1115 36.9224 52.8319 36.7257 52.611 36.4771C52.39 36.2286 52.2243 35.9351 52.1139 35.5968C52.0103 35.2516 51.9585 34.8753 51.9585 34.468ZM55.6557 33.9398C55.6626 33.5048 55.5522 33.1493 55.3243 32.8731C55.1034 32.59 54.7962 32.4485 54.4026 32.4485C54.1817 32.4485 53.9849 32.4934 53.8123 32.5831C53.6466 32.666 53.5051 32.7765 53.3877 32.9145C53.2703 33.0526 53.1771 33.2114 53.1081 33.3909C53.0459 33.5704 53.0045 33.7534 52.9838 33.9398H55.6557Z"
      fill={variant === 'white' ? 'white' : '#0C3642'}
    />
    <path
      d="M60.0323 31.6511C60.1151 31.6511 60.2083 31.658 60.3119 31.6718C60.4223 31.6787 60.5294 31.6925 60.6329 31.7132C60.7365 31.727 60.8297 31.7443 60.9125 31.765C61.0023 31.7788 61.0679 31.7926 61.1093 31.8064L60.9436 32.6453C60.8677 32.6177 60.7399 32.5866 60.5604 32.5521C60.3878 32.5106 60.1634 32.4899 59.8873 32.4899C59.7078 32.4899 59.5282 32.5106 59.3487 32.5521C59.1761 32.5866 59.0622 32.6108 59.007 32.6246V37.1503H58.0438V31.9928C58.2717 31.91 58.5548 31.834 58.8931 31.765C59.2314 31.689 59.6111 31.6511 60.0323 31.6511Z"
      fill={variant === 'white' ? 'white' : '#0C3642'}
    />
    <path
      d="M61.7384 34.468C61.7384 33.9916 61.8074 33.5773 61.9455 33.2252C62.0836 32.8662 62.2665 32.5693 62.4944 32.3346C62.7222 32.0998 62.9846 31.9238 63.2815 31.8064C63.5783 31.689 63.8821 31.6304 64.1928 31.6304C64.9178 31.6304 65.4736 31.8582 65.8602 32.3139C66.2468 32.7626 66.4401 33.4496 66.4401 34.3748C66.4401 34.4162 66.4401 34.4714 66.4401 34.5405C66.4401 34.6026 66.4367 34.6613 66.4298 34.7165H62.7429C62.7844 35.2758 62.9466 35.7004 63.2297 35.9904C63.5128 36.2803 63.9546 36.4253 64.5553 36.4253C64.8936 36.4253 65.1767 36.3977 65.4045 36.3425C65.6393 36.2803 65.8153 36.2217 65.9327 36.1664L66.0673 36.9742C65.9499 37.0364 65.7428 37.1019 65.4459 37.171C65.156 37.24 64.8246 37.2745 64.4517 37.2745C63.9822 37.2745 63.5749 37.2055 63.2297 37.0674C62.8914 36.9224 62.6118 36.7257 62.3908 36.4771C62.1699 36.2286 62.0042 35.9351 61.8937 35.5968C61.7902 35.2516 61.7384 34.8753 61.7384 34.468ZM65.4356 33.9398C65.4425 33.5048 65.332 33.1493 65.1042 32.8731C64.8832 32.59 64.576 32.4485 64.1825 32.4485C63.9615 32.4485 63.7648 32.4934 63.5922 32.5831C63.4265 32.666 63.2849 32.7765 63.1675 32.9145C63.0502 33.0526 62.957 33.2114 62.8879 33.3909C62.8258 33.5704 62.7844 33.7534 62.7636 33.9398H65.4356Z"
      fill={variant === 'white' ? 'white' : '#0C3642'}
    />
    <path
      d="M71.3034 32.9042C71.186 32.8075 71.0169 32.7143 70.7959 32.6246C70.575 32.5348 70.3334 32.4899 70.071 32.4899C69.7948 32.4899 69.5566 32.5417 69.3564 32.6453C69.1631 32.7419 69.0043 32.88 68.88 33.0595C68.7558 33.2321 68.666 33.4393 68.6108 33.6809C68.5555 33.9226 68.5279 34.1815 68.5279 34.4576C68.5279 35.0859 68.6833 35.5727 68.9939 35.9179C69.3046 36.2562 69.7189 36.4253 70.2367 36.4253C70.4991 36.4253 70.7165 36.415 70.8892 36.3943C71.0687 36.3666 71.2067 36.339 71.3034 36.3114V32.9042ZM71.3034 29.2795L72.2665 29.1138V36.9949C72.0456 37.0571 71.7625 37.1192 71.4173 37.1813C71.0721 37.2435 70.6751 37.2745 70.2263 37.2745C69.8121 37.2745 69.4393 37.209 69.1079 37.0778C68.7765 36.9466 68.4934 36.7602 68.2586 36.5185C68.0239 36.2769 67.8409 35.9835 67.7098 35.6383C67.5855 35.2861 67.5233 34.8926 67.5233 34.4576C67.5233 34.0434 67.5751 33.6636 67.6787 33.3184C67.7892 32.9732 67.948 32.6763 68.1551 32.4278C68.3622 32.1792 68.6142 31.9859 68.9111 31.8478C69.2149 31.7098 69.5601 31.6407 69.9467 31.6407C70.2574 31.6407 70.5301 31.6821 70.7649 31.765C71.0065 31.8478 71.186 31.9272 71.3034 32.0032V29.2795Z"
      fill={variant === 'white' ? 'white' : '#0C3642'}
    />
    <path
      d="M77.2822 32.0032C77.3996 31.9272 77.5757 31.8478 77.8104 31.765C78.0521 31.6821 78.3282 31.6407 78.6389 31.6407C79.0256 31.6407 79.3673 31.7098 79.6642 31.8478C79.968 31.9859 80.2234 32.1792 80.4306 32.4278C80.6377 32.6763 80.793 32.9732 80.8966 33.3184C81.0071 33.6636 81.0623 34.0434 81.0623 34.4576C81.0623 34.8926 80.9967 35.2861 80.8655 35.6383C80.7413 35.9835 80.5617 36.2769 80.327 36.5185C80.0923 36.7602 79.8092 36.9466 79.4778 37.0778C79.1464 37.209 78.7735 37.2745 78.3593 37.2745C77.9105 37.2745 77.5135 37.2435 77.1683 37.1813C76.8231 37.1192 76.54 37.0571 76.3191 36.9949V29.2795L77.2822 29.1138V32.0032ZM77.2822 36.3114C77.3789 36.339 77.5135 36.3666 77.6861 36.3943C77.8656 36.415 78.0866 36.4253 78.3489 36.4253C78.8668 36.4253 79.281 36.2562 79.5917 35.9179C79.9024 35.5727 80.0577 35.0859 80.0577 34.4576C80.0577 34.1815 80.0301 33.9226 79.9749 33.6809C79.9197 33.4393 79.8299 33.2321 79.7056 33.0595C79.5813 32.88 79.4191 32.7419 79.2189 32.6453C79.0256 32.5417 78.7908 32.4899 78.5146 32.4899C78.2523 32.4899 78.0106 32.5348 77.7897 32.6246C77.5688 32.7143 77.3996 32.8075 77.2822 32.9042V36.3114Z"
      fill={variant === 'white' ? 'white' : '#0C3642'}
    />
    <path
      d="M81.6544 38.1548C81.7303 38.1894 81.827 38.2204 81.9444 38.248C82.0686 38.2826 82.1895 38.2998 82.3068 38.2998C82.6866 38.2998 82.9834 38.2135 83.1975 38.0409C83.4115 37.8752 83.6048 37.6025 83.7774 37.2228C83.3425 36.3943 82.9351 35.5174 82.5554 34.5923C82.1826 33.6602 81.8719 32.7178 81.6233 31.765H82.6589C82.7349 32.0757 82.8246 32.4105 82.9282 32.7696C83.0387 33.1286 83.1595 33.4979 83.2907 33.8777C83.4219 34.2574 83.5634 34.6371 83.7153 35.0169C83.8672 35.3966 84.026 35.7625 84.1917 36.1146C84.454 35.3897 84.6819 34.6717 84.8752 33.9605C85.0685 33.2494 85.2515 32.5175 85.4241 31.765H86.4183C86.1697 32.7799 85.8936 33.7569 85.5898 34.6958C85.286 35.6279 84.9581 36.5013 84.6059 37.316C84.4679 37.6267 84.3229 37.8925 84.171 38.1134C84.026 38.3413 83.8637 38.5277 83.6842 38.6727C83.5047 38.8176 83.301 38.9247 83.0732 38.9937C82.8523 39.0627 82.6003 39.0973 82.3172 39.0973C82.2412 39.0973 82.1618 39.0904 82.079 39.0765C81.9961 39.0696 81.9133 39.0558 81.8304 39.0351C81.7545 39.0213 81.682 39.0041 81.613 38.9833C81.5508 38.9626 81.5059 38.9454 81.4783 38.9316L81.6544 38.1548Z"
      fill={variant === 'white' ? 'white' : '#0C3642'}
    />
    <path
      d="M128.714 31.5474C126.719 31.5474 126.252 32.3644 126.252 33.5289L127.87 33.5023C127.87 33.062 128.072 32.7516 128.753 32.7516C129.475 32.7516 129.716 33.0859 129.716 33.7145C129.501 33.7278 128.419 33.8498 128.286 33.8631C126.682 34.0514 126.037 34.4387 126.037 35.4812C126.037 36.6112 126.981 37.147 128.233 37.1656L128.223 37.1735C128.223 37.1735 129.287 37.155 129.92 37.0329C130.135 36.9905 130.371 36.9852 130.589 36.9958C131.021 37.017 131.387 37.0993 131.387 37.0993V34.009C131.387 32.5898 131.04 31.5474 128.711 31.5474M127.777 35.3883C127.777 35.1337 127.979 35.0011 128.647 34.9321L129.716 34.8127C129.703 35.4812 129.395 35.9905 128.647 35.9905C128.032 35.9905 127.777 35.7226 127.777 35.3883Z"
      fill={variant === 'white' ? 'white' : '#0D3642'}
    />
    <path
      d="M89.8601 37.2114V30.1396H92.6586C94.8497 30.1396 95.9824 31.4075 95.9824 33.6278C95.9824 35.848 94.7701 37.2114 92.5366 37.2114H89.8601ZM92.3005 31.5879H91.6533V35.7923H92.2608C93.542 35.7923 94.2449 35.3387 94.2316 33.6145C94.2184 31.8903 93.5844 31.5879 92.3005 31.5879Z"
      fill={variant === 'white' ? 'white' : '#0D3642'}
    />
    <path
      d="M100.028 37.2559C97.67 37.2559 97.0917 35.8368 97.0917 34.3593C97.0917 32.8818 97.6992 31.4653 100.015 31.4653C102.331 31.4653 102.774 33.2982 102.662 34.911H98.8159C98.8292 35.6564 99.1873 36.0543 99.9327 36.0543C100.567 36.0543 100.829 35.7917 100.898 35.476H102.691C102.596 36.5106 101.739 37.2533 100.031 37.2533L100.028 37.2559ZM99.8902 32.7067C99.2006 32.7067 98.8822 33.147 98.8292 33.7412H100.898C100.898 33.163 100.622 32.7067 99.8929 32.7067H99.8902Z"
      fill={variant === 'white' ? 'white' : '#0D3642'}
    />
    <path
      d="M123.666 37.1988H121.613L120.276 32.1801L118.939 37.1988H116.899L114.859 30.127H116.748L117.96 35.3526L119.31 30.127H121.324L122.66 35.3526L123.886 30.127H125.706L123.666 37.1988Z"
      fill={variant === 'white' ? 'white' : '#0D3642'}
    />
    <path
      d="M136.156 37.1987H134.225L131.978 31.5593H133.88L135.191 35.6549L136.554 31.5593H138.525L136.154 37.1987H136.156Z"
      fill={variant === 'white' ? 'white' : '#0D3642'}
    />
    <path
      d="M141.863 37.2559C139.505 37.2559 138.926 35.8368 138.926 34.3593C138.926 32.8818 139.534 31.4653 141.85 31.4653C144.165 31.4653 144.608 33.2982 144.497 34.911H140.651C140.664 35.6564 141.022 36.0543 141.767 36.0543C142.401 36.0543 142.664 35.7917 142.733 35.476H144.526C144.431 36.5106 143.574 37.2533 141.866 37.2533L141.863 37.2559ZM141.725 32.7067C141.035 32.7067 140.717 33.147 140.664 33.7412H142.733C142.733 33.163 142.457 32.7067 141.728 32.7067H141.725Z"
      fill={variant === 'white' ? 'white' : '#0D3642'}
    />
    <path
      d="M110.701 35.3324V30.2341H112.359V31.6135H113.749V32.9637H112.398V35.1574C112.398 35.5712 112.491 35.9213 113.749 35.786V37.1362C111.287 37.298 110.698 36.8152 110.698 35.3298"
      fill={variant === 'white' ? 'white' : '#0D3642'}
    />
    <path
      d="M108.873 36.5915C109.396 36.0318 109.595 35.2016 109.595 34.374C109.595 32.9256 109.085 31.464 107.332 31.464C106.465 31.464 105.913 31.7664 105.65 32.1113V29.395H103.939V37.199H103.958V37.1937C104 37.1857 104.342 37.1114 104.751 37.0929C104.971 37.0823 105.207 37.0876 105.419 37.13C106.056 37.252 107.117 37.2706 107.117 37.2706L107.106 37.2626C107.929 37.2626 108.422 37.0398 108.804 36.6658C108.815 36.6552 108.825 36.6446 108.836 36.634C108.849 36.6207 108.862 36.6101 108.873 36.5968H108.868L108.873 36.5915ZM106.74 32.8832C107.677 32.8832 107.831 33.5039 107.831 34.3713C107.831 35.2387 107.679 35.8753 106.754 35.8753C105.828 35.8753 105.637 35.2679 105.637 34.3713C105.637 33.4747 105.762 32.8832 106.74 32.8832Z"
      fill={variant === 'white' ? 'white' : '#0D3642'}
    />
    <path
      d="M105.625 22.8412C104.761 22.8412 103.898 22.8109 103.035 22.7503C102.187 22.7049 101.301 22.5837 100.377 22.3869V7.12189C101.104 6.9856 101.899 6.88716 102.762 6.82659C103.626 6.75087 104.428 6.71301 105.17 6.71301C106.17 6.71301 107.086 6.78873 107.919 6.94017C108.767 7.07646 109.494 7.31876 110.1 7.66707C110.705 8.01538 111.175 8.47726 111.508 9.05273C111.856 9.61305 112.03 10.3097 112.03 11.1426C112.03 12.3995 111.425 13.3914 110.213 14.1183C111.213 14.4969 111.894 15.0118 112.258 15.663C112.621 16.3142 112.803 17.0487 112.803 17.8664C112.803 19.5171 112.197 20.7589 110.985 21.5918C109.789 22.4247 108.002 22.8412 105.625 22.8412ZM103.83 15.7766V19.8427C104.087 19.873 104.368 19.8957 104.67 19.9108C104.973 19.926 105.307 19.9336 105.67 19.9336C106.73 19.9336 107.586 19.7821 108.237 19.4792C108.888 19.1764 109.214 18.616 109.214 17.7983C109.214 17.0714 108.941 16.5565 108.396 16.2536C107.851 15.9356 107.071 15.7766 106.056 15.7766H103.83ZM103.83 13.0734H105.556C106.647 13.0734 107.427 12.9371 107.896 12.6645C108.366 12.3768 108.6 11.9225 108.6 11.3016C108.6 10.6655 108.358 10.2188 107.873 9.96136C107.389 9.70391 106.677 9.57519 105.738 9.57519C105.435 9.57519 105.11 9.58276 104.761 9.59791C104.413 9.59791 104.103 9.61305 103.83 9.64334V13.0734Z"
      fill={variant === 'white' ? 'white' : '#00695C'}
    />
    <path
      d="M118.615 14.7544C118.615 15.5267 118.706 16.2233 118.888 16.8442C119.085 17.4651 119.357 18.0027 119.705 18.457C120.069 18.8962 120.508 19.2369 121.023 19.4792C121.538 19.7215 122.128 19.8427 122.795 19.8427C123.446 19.8427 124.029 19.7215 124.544 19.4792C125.074 19.2369 125.513 18.8962 125.861 18.457C126.225 18.0027 126.497 17.4651 126.679 16.8442C126.876 16.2233 126.974 15.5267 126.974 14.7544C126.974 13.982 126.876 13.2854 126.679 12.6645C126.497 12.0285 126.225 11.4909 125.861 11.0517C125.513 10.5974 125.074 10.2491 124.544 10.0068C124.029 9.76449 123.446 9.64334 122.795 9.64334C122.128 9.64334 121.538 9.77206 121.023 10.0295C120.508 10.2718 120.069 10.6201 119.705 11.0744C119.357 11.5136 119.085 12.0512 118.888 12.6872C118.706 13.3081 118.615 13.9972 118.615 14.7544ZM130.632 14.7544C130.632 16.1022 130.427 17.291 130.018 18.3207C129.625 19.3354 129.079 20.191 128.383 20.8876C127.686 21.5691 126.853 22.084 125.884 22.4323C124.93 22.7806 123.9 22.9547 122.795 22.9547C121.72 22.9547 120.705 22.7806 119.751 22.4323C118.797 22.084 117.964 21.5691 117.252 20.8876C116.54 20.191 115.98 19.3354 115.571 18.3207C115.162 17.291 114.958 16.1022 114.958 14.7544C114.958 13.4066 115.17 12.2254 115.594 11.2107C116.018 10.1809 116.586 9.31775 117.298 8.62113C118.024 7.92452 118.857 7.40205 119.796 7.05375C120.75 6.70544 121.75 6.53129 122.795 6.53129C123.87 6.53129 124.885 6.70544 125.839 7.05375C126.793 7.40205 127.626 7.92452 128.337 8.62113C129.049 9.31775 129.609 10.1809 130.018 11.2107C130.427 12.2254 130.632 13.4066 130.632 14.7544Z"
      fill={variant === 'white' ? 'white' : '#00695C'}
    />
    <path
      d="M133.586 6.89474H137.129V22.6367H133.586V6.89474Z"
      fill={variant === 'white' ? 'white' : '#00695C'}
    />
    <path
      d="M145.384 6.71301C147.747 6.71301 149.556 7.13704 150.813 7.98509C152.07 8.818 152.699 10.1204 152.699 11.8922C152.699 12.9977 152.441 13.8987 151.926 14.5954C151.427 15.2768 150.7 15.8144 149.746 16.2082C150.064 16.6019 150.397 17.0562 150.745 17.5711C151.094 18.0709 151.434 18.6009 151.767 19.1612C152.116 19.7064 152.449 20.2819 152.767 20.8876C153.085 21.4782 153.38 22.0613 153.653 22.6367H149.678C149.39 22.1218 149.095 21.5994 148.792 21.0693C148.504 20.5393 148.201 20.0244 147.883 19.5247C147.58 19.0249 147.277 18.5555 146.974 18.1163C146.672 17.662 146.369 17.2531 146.066 16.8896H144.317V22.6367H140.773V7.12189C141.545 6.97046 142.34 6.86445 143.158 6.80387C143.991 6.7433 144.733 6.71301 145.384 6.71301ZM145.589 9.7342C145.331 9.7342 145.097 9.74177 144.885 9.75692C144.688 9.77206 144.498 9.7872 144.317 9.80235V14.0729H145.316C146.649 14.0729 147.603 13.9063 148.178 13.5732C148.754 13.24 149.042 12.6721 149.042 11.8695C149.042 11.0971 148.746 10.552 148.156 10.2339C147.58 9.90078 146.725 9.7342 145.589 9.7342Z"
      fill={variant === 'white' ? 'white' : '#00695C'}
    />
    <path
      d="M32.1328 22.5446V6.80264H41.628V8.68804H34.3362V13.4356H40.8102V15.2983H34.3362V22.5446H32.1328Z"
      fill={variant === 'white' ? 'white' : '#00695C'}
    />
    <path
      d="M46.3099 22.5446H44.1973V10.7325H46.3099V22.5446ZM45.2423 8.59718C44.8637 8.59718 44.5381 8.47603 44.2655 8.23373C44.008 7.97628 43.8793 7.63555 43.8793 7.21152C43.8793 6.7875 44.008 6.45433 44.2655 6.21203C44.5381 5.95459 44.8637 5.82586 45.2423 5.82586C45.6209 5.82586 45.9389 5.95459 46.1963 6.21203C46.4689 6.45433 46.6052 6.7875 46.6052 7.21152C46.6052 7.63555 46.4689 7.97628 46.1963 8.23373C45.9389 8.47603 45.6209 8.59718 45.2423 8.59718Z"
      fill={variant === 'white' ? 'white' : '#00695C'}
    />
    <path
      d="M53.4865 22.7718C52.1841 22.7415 51.2603 22.4613 50.7151 21.9313C50.17 21.4013 49.8974 20.5759 49.8974 19.4553V5.28069L52.0099 4.91724V19.1146C52.0099 19.4629 52.0402 19.7506 52.1008 19.9778C52.1614 20.2049 52.2598 20.3866 52.3961 20.5229C52.5324 20.6592 52.7141 20.7652 52.9413 20.8409C53.1684 20.9015 53.4486 20.9545 53.7818 21L53.4865 22.7718Z"
      fill={variant === 'white' ? 'white' : '#00695C'}
    />
    <path
      d="M55.4954 16.6613C55.4954 15.6163 55.6468 14.7077 55.9497 13.9354C56.2526 13.1479 56.6539 12.4967 57.1536 11.9818C57.6534 11.4669 58.2288 11.0808 58.88 10.8233C59.5312 10.5659 60.1975 10.4372 60.879 10.4372C62.4691 10.4372 63.6882 10.9369 64.5362 11.9364C65.3843 12.9207 65.8083 14.4275 65.8083 16.4568C65.8083 16.5477 65.8083 16.6688 65.8083 16.8203C65.8083 16.9566 65.8007 17.0853 65.7856 17.2064H57.6988C57.7897 18.4331 58.1455 19.3644 58.7664 20.0005C59.3873 20.6365 60.3565 20.9545 61.6741 20.9545C62.4161 20.9545 63.037 20.894 63.5367 20.7728C64.0516 20.6365 64.4378 20.5078 64.6952 20.3866L64.9906 22.1585C64.7331 22.2948 64.2788 22.4386 63.6276 22.5901C62.9916 22.7415 62.2647 22.8172 61.4469 22.8172C60.4171 22.8172 59.5236 22.6658 58.7664 22.3629C58.0244 22.0449 57.4111 21.6133 56.9265 21.0681C56.4419 20.5229 56.0784 19.8793 55.8361 19.1373C55.609 18.3801 55.4954 17.5547 55.4954 16.6613ZM63.6049 15.5028C63.62 14.5487 63.3777 13.7688 62.878 13.163C62.3934 12.5421 61.7195 12.2317 60.8563 12.2317C60.3717 12.2317 59.9401 12.3301 59.5615 12.527C59.198 12.7087 58.8876 12.951 58.6302 13.2539C58.3727 13.5568 58.1683 13.9051 58.0168 14.2988C57.8805 14.6926 57.7897 15.0939 57.7442 15.5028H63.6049Z"
      fill={variant === 'white' ? 'white' : '#00695C'}
    />
    <path
      d="M76.0439 20.3639C75.8924 20.0005 75.688 19.5386 75.4305 18.9783C75.1882 18.4179 74.9232 17.8122 74.6355 17.161C74.3478 16.5098 74.0373 15.8435 73.7042 15.162C73.3861 14.4654 73.0833 13.8142 72.7955 13.2085C72.5078 12.5876 72.2352 12.0348 71.9778 11.5502C71.7355 11.0656 71.5386 10.6946 71.3871 10.4372C71.2206 12.2241 71.0843 14.1625 70.9783 16.2524C70.8723 18.3271 70.7814 20.4245 70.7057 22.5446H68.5477C68.6083 21.1817 68.6764 19.8112 68.7521 18.4331C68.8279 17.0399 68.9111 15.6769 69.002 14.3443C69.108 12.9965 69.214 11.6865 69.32 10.4144C69.4412 9.14236 69.5699 7.93843 69.7062 6.80264H71.637C72.0459 7.46897 72.4851 8.25644 72.9545 9.16507C73.424 10.0737 73.8934 11.0278 74.3629 12.0273C74.8324 13.0116 75.2867 14.0035 75.7258 15.003C76.165 15.9874 76.5663 16.8884 76.9298 17.7062C77.2932 16.8884 77.6945 15.9874 78.1337 15.003C78.5729 14.0035 79.0272 13.0116 79.4967 12.0273C79.9661 11.0278 80.4356 10.0737 80.905 9.16507C81.3745 8.25644 81.8137 7.46897 82.2225 6.80264H84.1534C84.6683 11.8758 85.0544 17.1231 85.3119 22.5446H83.1539C83.0782 20.4245 82.9873 18.3271 82.8813 16.2524C82.7753 14.1625 82.639 12.2241 82.4724 10.4372C82.321 10.6946 82.1165 11.0656 81.8591 11.5502C81.6168 12.0348 81.3518 12.5876 81.064 13.2085C80.7763 13.8142 80.4659 14.4654 80.1327 15.162C79.8147 15.8435 79.5118 16.5098 79.2241 17.161C78.9363 17.8122 78.6637 18.4179 78.4063 18.9783C78.164 19.5386 77.9671 20.0005 77.8157 20.3639H76.0439Z"
      fill={variant === 'white' ? 'white' : '#00695C'}
    />
    <path
      d="M87.2448 24.748C87.4114 24.8238 87.6234 24.8919 87.8809 24.9525C88.1535 25.0282 88.4185 25.0661 88.6759 25.0661C89.5088 25.0661 90.16 24.8768 90.6295 24.4982C91.0989 24.1347 91.523 23.5365 91.9016 22.7036C90.9475 20.8864 90.054 18.9631 89.2211 16.9338C88.4033 14.8894 87.7219 12.8223 87.1767 10.7325H89.4483C89.6148 11.4139 89.8117 12.1484 90.0389 12.9359C90.2812 13.7234 90.5462 14.5336 90.8339 15.3665C91.1217 16.1994 91.4321 17.0323 91.7653 17.8652C92.0984 18.6981 92.4467 19.5007 92.8102 20.2731C93.3857 18.683 93.8854 17.108 94.3094 15.5482C94.7335 13.9884 95.1348 12.3831 95.5134 10.7325H97.6941C97.1489 12.9586 96.5431 15.1014 95.8768 17.161C95.2105 19.2054 94.4912 21.1211 93.7188 22.9081C93.4159 23.5895 93.0979 24.1726 92.7648 24.6572C92.4467 25.1569 92.0909 25.5658 91.6971 25.8838C91.3034 26.2019 90.8566 26.4366 90.3569 26.588C89.8723 26.7395 89.3195 26.8152 88.6986 26.8152C88.5321 26.8152 88.3579 26.8 88.1762 26.7697C87.9945 26.7546 87.8127 26.7243 87.631 26.6789C87.4644 26.6486 87.3054 26.6107 87.154 26.5653C87.0177 26.5199 86.9193 26.482 86.8587 26.4517L87.2448 24.748Z"
      fill={variant === 'white' ? 'white' : '#00695C'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.82184 5.53721C2.45978 4.87728 3.33237 4.5 4.24967 4.5H15.1247C15.3958 4.5 15.6552 4.61006 15.8437 4.80498L21.8853 11.055C22.0656 11.2414 22.1663 11.4907 22.1663 11.75V28C22.1663 28.9175 21.8143 29.804 21.1775 30.4628C20.5396 31.1227 19.667 31.5 18.7497 31.5L12.5029 31.5L12.5029 29.5L18.7497 29.5C19.1142 29.5 19.471 29.3505 19.7395 29.0727C20.0091 28.7938 20.1663 28.4086 20.1663 28V12.1543L14.7005 6.5H4.24967C3.8851 6.5 3.52831 6.6495 3.25982 6.92726C2.99021 7.20616 2.83301 7.59144 2.83301 8V14H0.833008V8C0.833008 7.08248 1.18503 6.19599 1.82184 5.53721Z"
      fill={variant === 'white' ? 'white' : '#00695C'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.917 4.5V10.5C14.917 10.9086 15.0742 11.2938 15.3438 11.5727C15.6123 11.8505 15.9691 12 16.3337 12H22.167V14H16.3337C15.4164 14 14.5438 13.6227 13.9058 12.9628C13.269 12.304 12.917 11.4175 12.917 10.5V4.5H14.917Z"
      fill={variant === 'white' ? 'white' : '#00695C'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.820312 20.75H13.2839V22.75H0.820312V20.75Z"
      fill={variant === 'white' ? 'white' : '#00695C'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.89897 16.586L14.219 21.055C14.5937 21.4426 14.5937 22.0574 14.219 22.445L9.89897 26.914L8.46099 25.524L12.1092 21.75L8.46099 17.976L9.89897 16.586Z"
      fill={variant === 'white' ? 'white' : '#00695C'}
    />
  </svg>
)
